import React from 'react'
import Layout from '../components/layout'

const WhyUsPage = () => {
  return (
    <Layout pageTitle="Why us" title="MRC Agrotech Ltd - Why Us" description="The world is changing faster than we could imagine. With technology up-gradation happening frequently it is very easy to become redundant and obsolete.">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          <p data-sal="fade" data-sal-easing="ease-out">The world is changing faster than we could imagine. With technology up-gradation happening frequently it is very easy to become redundant and obsolete. Hence, businesses need to be on-toes to ride the wave and grow with the tide. Reason why firms like MRC seem like shining light in such scenarios.</p>
          <p data-sal="fade" data-sal-delay="100" data-sal-easing="ease-out">MRC Agrotech, right since its inception has been agile, innovative and expansive. Built on a strong foundation, MRC has managed to scale up operations, add territories, expand product range and also bring in more buyers and investors.</p>
        </div>
      </div>
    </Layout>
  )
}

export default WhyUsPage
